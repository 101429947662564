import './ServicesPage.css';
import { Helmet } from 'react-helmet';
import Header from '../../components/header/Header';
import ServicesCard from '../../components/ServicesCard/ServicesCard';
import background_image from '../../images/services-background.png';
import background_image_2 from '../../images/services-background-2.png';
import orange_line from '../../images/orange-line.png';
import ServicesPageNavbar from '../../components/ServicesPageNavbar/ServicesPageNavbar';

const ServicesPage = ({ headers, services, theme }) => {
  return (
    <div>
      <Helmet>
        <title>
          Scisco Genetics Typing Services - HLA Typing, KIR Typing, FcGR typing and Chimerism Testing
        </title>
        <meta
          name='description'
          content='Typing Services for HLA, KIR, FcR, Chimerism testing and targeted sequencing upon special request - HLA typing, KIR typing, FcGR typing, Chimerism testing'
        />
      </Helmet>
      <Header header={headers.filter((header) => header.key === 'services')} />
      <ServicesPageNavbar />
      <div className='services-main-container'>
        <section>
          <h5 className='section-title'>
            What we do{' '}
            <img
              src={orange_line}
              className='orange-line'
              alt='decorative line'
            />{' '}
          </h5>
          <h2 className='section-desc'>
            Providing high quality sequence-based genotyping solutions and
            services for complex immune gene systems
          </h2>
        </section>
        <img
          src={background_image}
          className='services-background-image'
          alt='services background'
        />
        <img
          src={background_image_2}
          className='second-image'
          alt='services background'
        />
        <ServicesCard
          services={services}
          theme={theme}
          className='services-cards'
        />
      </div>
    </div>
  );
};

export default ServicesPage;
