import ashi_logo from '../images/resources/ashi-logo.png';
import ipd_imgt_logo from '../images/resources/ipd-logo.png';
import ipd_kir_logo from '../images/resources/ipd-kir-logo.png';
import hla_nomenclature_logo from '../images/resources/hla-nomenclature-logo.png';
import allele_freq_logo from '../images/resources/allele-frequencies-logo.png';
import a_n_logo from '../images/resources/anthonynolan-logo.png';
import hutch_logo from '../images/resources/fredhutch-logo.png';
import nmdp_logo from '../images/resources/nmdp-logo.png';
import wmda_logo from '../images/resources/wmda-logo.png';
import idawg_logo from '../images/resources/idawg-logo.png';

export const resources = [
  {
    logo: ashi_logo,
    name: 'American Society of Histocompatability and Immunogenetics',
    description:
      'An international society for the advancement of the science and clinical application of histocompatibility and immunogenetics and setting the highest standards of laboratory testing.',
    link: 'https://www.ashi-hla.org/',
  },
  {
    logo: ipd_imgt_logo,
    name: 'IMGT/HLA Database',
    description:
      'A database of HLA sequences and part of the ImMunoGeneTics project (IMGT).',
    link: 'https://www.ebi.ac.uk/ipd/imgt/hla/',
  },
  {
    logo: ipd_kir_logo,
    name: 'IPD-KIR Database',
    description: 'A repository of human KIR sequences.',
    link: 'https://www.ebi.ac.uk/ipd/kir/',
  },
  {
    logo: hla_nomenclature_logo,
    name: 'HLA Nomenclature',
    description: 'An explanation of HLA nomenclature.',
    link: 'https://hla.alleles.org/nomenclature/index.html',
  },
  {
    logo: allele_freq_logo,
    name: 'The Allele Frequency Net Database',
    description:
      'A database of allele frequencies from different polymorphic areas in the human genome.',
    link: 'http://www.allelefrequencies.net/',
  },
  {
    logo: a_n_logo,
    name: 'Anthony Nolan',
    description:
      'A registery of stem cell donors and a dedicated cord blood bank.',
    link: 'https://www.anthonynolan.org/',
  },
  {
    logo: hutch_logo,
    name: 'International Histocompatability Working Group',
    description:
      'This group supports the projects of the 13th international himstocompatibility workshop, housed at Fred Hutchinson Cancer Research Center in Seattle, WA. They keep an inventory of B-LCL lines and HLA donors from diverse population groups.',
    link: 'https://www.fredhutch.org/en/research/institutes-networks-ircs/international-histocompatibility-working-group.html',
  },
  {
    logo: nmdp_logo,
    name: 'National Marrow Donor Program',
    description:
      'The largest registry of marrow and umbilical blood donors in the world.',
    link: 'https://bethematch.org/',
  },
  {
    logo: wmda_logo,
    name: 'Bone Marrow Donors Worldwide',
    description:
      'The World Marrow Donor Association (WMDA) is made up of organisations and individuals who promote global collaboration and best practices for the benefit of stem cell donors and transplant patients.',
    link: 'https://wmda.info/',
  },
  {
    logo: idawg_logo,
    name: 'Immunogenetics Data Analysis Working Group',
    description:
      'A group formed by histocompatibility and immunogenetics investigators to share immunogenomic data and to foster the consistent analysis of data by the immunogenetics community.',
    link: 'Visit site',
  },
];
