import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { videos } from "../../database/videos";
import "./Videos.css";

const Videos = () => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = videos.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<div className="process-container">
			<h1 className="kit-title">How-to videos</h1>
			<div className="videos">
				<Box sx={{ maxWidth: "600px", flexGrow: 1 }}>
					<Paper
						square
						elevation={0}
						sx={{
							display: "flex",
							alignItems: "center",
							width: "600px",
							pl: 2,
							bgcolor: "background.default",
						}}
					>
						<Typography>{videos[activeStep].title}</Typography>
					</Paper>
					<SwipeableViews
						axis={theme.direction === "rtl" ? "x-reverse" : "x"}
						index={activeStep}
						onChangeIndex={handleStepChange}
						enableMouseEvents
					>
						{videos.map((video, index) => (
							<div key={video.title} style={{ width: "600px", height: "100%" }}>
								{" "}
								{/* Ensure this div is always rendered */}
								{Math.abs(activeStep - index) <= 2 && (
									<iframe
										width="600"
										height="315"
										style={{ border: 0 }}
										src={video.srcLink}
										title={video.title}
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
										referrerPolicy="strict-origin-when-cross-origin"
										allowFullScreen
									></iframe>
								)}
							</div>
						))}
					</SwipeableViews>

					<MobileStepper
						steps={maxSteps}
						position="static"
						activeStep={activeStep}
						nextButton={
							<Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
								Next
								{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
							</Button>
						}
						backButton={
							<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
								{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
								Back
							</Button>
						}
					/>
				</Box>
			</div>
		</div>
	);
};

export default Videos;
