import hla_kit from '../images/kits/hla-kit.png';
import chim_kit from '../images/kits/chimerism-kit.png';
import fcr_kit from '../images/kits/fcr-kit.png';
import hla_brochure from '../files/kits/brochures/hla-brochure.pdf'
import hla_insert from '../files/kits/inserts/hla-insert.pdf'
import hla_protocol from '../files/kits/protocols/hla-protocol.pdf'
import chim_brochure from '../files/kits/brochures/chimerism-brochure.pdf'
import chim_insert from '../files/kits/inserts/chimerism-insert.pdf'
import chim_protocol from '../files/kits/protocols/chimerism-protocol.pdf'

export const kits = [
  {
    name: 'ScisGo™ HLA v6 Kits',
    headline: 'Phased 3-field HLA typing in a single assay using proven Scisco Genetics technology. Cat. No. HLA-24S-v6',
    highlights: [
      'Class I HLA -A, -B, -C all exons',
      'Class II HLA-DRB1345, -DQA1, -DQB1, -DPA1, and HLA-DPB1 all exons',
      'Simple, PCR-only protocol',
      'Phased 3-field typing across all loci',
      'DNA to Data in 30 hours',
      'No robotics needed',
      'Simple, PCR-only protocol',
      'Total DNA input as low as 10 ng',
      'All known exon and intron encoded null alleles detected',
      'DNA from a variety of sources including saliva, buccal swabs, and dried blood spots',
    ],
    overview_1: 'The human leukocyte antigens (HLA) are intimately involved in the innate and adaptive immune responses, communicating normal and abnormal cellular status to surveying immune cells. These highly polymorphic genes can greatly affect the outcome of hematopoietic cell and solid organ transplantation, in addition to their consequential roles in infectious disease, autoimmunity, and cancer.',
    overview_2: 'Our NGS solution for HLA class I and II typing is carried out via multiplex PCR of HLA specific amplicons. Exon and flanking intron sequences from the HLA genes are amplified in 4 reactions, simultaneously tagged with index sequences (to distinguish samples), pooled, and sequenced. Data can be stored, analyzed on the cloud, and visualized on your desktop computer using our GeMS (Genetics Management System) analysis software.',
    doc_title: 'HLA V6',
    brochure: hla_brochure,
    insert: hla_insert,
    protocol: hla_protocol,
    image: hla_kit,
    className: 'kit-container hla-kit',
    background_num: '01',
    
  },
  {
    name: 'ScisGo™ Chimerism Kits',
    headline: 'Post-transplant analysis of the donor/recipient origin of white blood cells in peripheral blood and/or marrow. Cat. No. CHIM-24S-v5',
    highlights: [
      'All chromosomes sampled',
      'Next Generation Sensitivity, down to 0.1%',
      'Simple PCR-only protocol',
      'No robotics needed',
      'DNA to Data in 12 hours',
      'Up to 3 donors detected post-transplant',
      'Data analysis provides 95% confidence intervals and sensitivity levels',
      'Total DNA input as low as 0.5 ng (equivalent to ~100 cells)',
    ],
    overview_1: 'A test for chimerism after allogeneic hematopoietic cell transplantation (HCT) is routinely performed as a prognostic measure of engraftment and related clinical outcomes. Chimerism tests employ methods commonly used in identity testing to distinguish and quantitate donor and recipient cells present in blood, bone marrow, and various tissues.',
    overview_2: 'Our 4-reaction Chimerism assay employs simple to perform laboratory steps and takes advantage of Next Generation Sequencing (NGS) technology to advance testing into the next generation of sensitivity and accuracy. Our assay can be adjusted for capacity and sensitivity by varying the number of reactions used and samples analyzed. Turnaround times from DNA to data are comparable to other commonly used methodologies.',
    doc_title: 'CHIM MD and HS',
    brochure: chim_brochure,
    insert: chim_insert,
    protocol: chim_protocol,
    image: chim_kit,
    className: 'kit-container chimerism-kit',
    background_num: '02',
  },
  {
    name: 'ScisGo™ Fc-Fcr Kits',
    headline: 'High-resolution genotyping of the Fc Receptor genes and  IgG constant region domains in a single system. Cat. No. FcFcR-96S-v2',
    highlights: [
      'All known FcR functional variants',
      'Phased CH1, CH2, CH3 IgG variants',
      'Simple, PCR-only protocol',
      'No robotics needed',
      'Total DNA input as low as 10 ng ',
      'DNA to Data in 36 hours',
      'Up to 384 samples can be analyzed on a single run',
    ],
    overview_1: 'The Fc Receptor genes, including the Fc Gamma Receptor gene family, encode a variety of FcR that interact functionally with the IgG1-4 heavy chain CH1, CH2, CH3  constant region domains. This interaction is central to the functional downstream interactions in pathways contributing to cellular and humoral immune responses.',
    overview_2: 'Our 4-reaction Fc-FcR assay employs simple to perform laboratory steps and takes advantage of Next Generation Sequencing (NGS) technology to advance testing into the next generation of detail and accuracy. Our assay can analyze up to 384 samples in a single NGS run allowing large scale high-throughput data acquisition. ',
    doc_title: 'Fc-FcR',
    brochure: '',
    insert: '',
    protocol: '',
    image: fcr_kit,
    className: 'kit-container fcr-kit',
    background_num: '03',
  },
];