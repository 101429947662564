import './PublicationsCard.css';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

const PublicationsCard = ({ publications }) => {
  const publication = publications.map((p, index) => {
    return (
      <div key={index} className='publication-card'>
        <div className='pub-info'>
          <section className='pub-header'>
            <h5 className='journal-name'>{p.journal_name}</h5>
            <h4 className='pub-title'>{p.title}</h4>
          </section>
          <p className='pub-desc'>{p.description}</p>
          <a
            href={p.link}
            target='_blank'
            rel='noreferrer'
            className='pub-link'
          > 
            <p>View article </p>
            <aside>
              <EastOutlinedIcon />
            </aside>
          </a>
        </div>
      </div>
    );
  });

  return <div className='pub-cards'>{publication}</div>;
};

export default PublicationsCard;
