import './ServiceHomePageCard.css';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

const ServiceHomePageCard = ({ service_cards }) => {
  const service_card = service_cards.map((sc, index) => {
    return (
      <a key={index} className='card' href={sc.link}>
        <div className={sc.className}>
          <div className={sc.box_className}>
            <img className='mini-img' src={sc.mini_image} alt='service logo' />
          </div>
          <img src={sc.main_image} alt='service' />
          <div className='card-desc'>
            <p className='card-title'>{sc.title}</p>
            <p className='card-info'>{sc.description}</p>
            <div className='learn-more'>
              <p>Learn more</p>
              <aside><EastOutlinedIcon/></aside>
            </div>
          </div>
        </div>
      </a>
    );
  })
  return (
    <div className='cards'>{service_card}</div>
  )
}

export default ServiceHomePageCard;