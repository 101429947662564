import './KitProcess.css';
import process_1_img from '../../images/kit-process/1-stage-1-pcr.png';
import process_2_img from '../../images/kit-process/2-stage-2-pcr.png';
import process_3_img from '../../images/kit-process/3-test-tubes.png';
import process_4_img from '../../images/kit-process/4-pool.png';
import process_5_img from '../../images/kit-process/5-mgs.png';
import process_6_img from '../../images/kit-process/6-report.png';

const KitProcess = () => {
  const process_steps = [
    {
      image: process_1_img,
      title: 'Stage 1 PCR',
      description:
        'Add DNA and master mix to pre-plated amplicon primer mixes.',
        hr_row_className: 'row-hr',
        hr_col_className: 'col-hr',
    },
    {
      image: process_2_img,
      title: 'Stage 2 PCR',
      description: 'Add secondary master mix. No plate transfer.',
      hr_row_className: 'row-hr',
      hr_col_className: 'col-hr',
    },
    {
      image: process_3_img,
      title: 'Pool and Purify',
      description: 'Combine all reactions and column purify.',
      hr_row_className: 'row-hr',
      hr_col_className: 'col-hr',
    },
    {
      image: process_4_img,
      title: 'Quantify and Pool',
      description: 'Combine pools for sequencing.',
      hr_row_className: 'row-hr',
      hr_col_className: 'col-hr',
    },
    {
      image: process_5_img,
      title: 'NGS Sequencing',
      description: 'Sequencing process.',
      hr_row_className: 'row-hr',
      hr_col_className: 'col-hr',
    },
    {
      image: process_6_img,
      title: 'Analysis and Reporting',
      description: 'Get your genotyping report in custom formats.',
      hr_row_className: 'process-no-hr',
      hr_col_className: 'process-no-hr',
    },
  ];

  const process = process_steps.map((p, index) => (
    <section className='process-card' key={index}>
      <div className='process-image-container'>
        <div className='process-image'>
          <img src={p.image} alt='process' />
        </div>
        <hr className={p.hr_row_className} />
      </div>

      <div className='process-overview'>
        <h4 className='process-title'>{p.title}</h4>
        <p className='process-desc'>{p.description}</p>
      </div>
      <hr className={p.hr_col_className} />
    </section>
  ));

  return (
    <>
      <div className='process-container'>
        <h1 className='kit-title'>The ScisGo Kit Workflow</h1>
        <h2 className='kit-section'>All ScisGo genotyping assays operate with the same simple steps</h2>
        <div className='process-cards'>{process}</div>
      </div>
    </>
  );
};

export default KitProcess;
