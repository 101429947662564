import './ServicesCard.css';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

const ServicesCard = ({ services, theme }) => {
  const service = services.map((s) => {
    const more_button = s.key === 1 || s.key === 2 || s.key === 3 || s.key === 4;
    return (
      <div key={s.key} className={s.className} id={s.nickname}>
        <div className={s.className2}>
          <img src={s.mini_img} className='service-icon' alt='icon' />
          <img
            className='service-image'
            src={s.services_page_image}
            alt='service'
          />
        </div>
        <div className='service-info'>
          <h4 className='service-title'>{s.services_page_title}</h4>
          <h2 className='service-headline'>{s.headline}</h2>
          <p className='service-desc'>{s.services_page_description}</p>
          {more_button ? 
            <a
            className='service-sample-download'
            href={s.sample_report}
            target='_blank'
            rel='noreferrer'
          >
            Sample {s.nickname} report <FileDownloadOutlinedIcon />
          </a> : ''
          }
          {/* <a
            className='service-sample-download'
            href={s.sample_report}
            target='_blank'
            rel='noreferrer'
          >
            Sample {s.nickname} report <FileDownloadOutlinedIcon />
          </a> */}
          <ThemeProvider theme={theme}>
            {more_button ?
              <Button
              
              href={s.link}
              color='primary'
              variant='contained'
              sx={{ borderRadius: '30px', fontWeight: 700 }}
            >
              {' '}
              Learn more
            </Button>  : ''         
            }

          </ThemeProvider>
        </div>
      </div>
    );
  });

  return <div className='service-cards'>{service}</div>;
};

export default ServicesCard;
