import resources_page_image from '../images/headers/resources-header.png';
import services_page_image from '../images/headers/services-header.png';
import kits_page_image from '../images/headers/kits-header.png';
import technology_page_image from '../images/headers/technology-header.png';
import contact_page_image from '../images/headers/contact-header.png';
import service_page_image from '../images/headers/service-header.png';
import about_page_image from '../images/headers/about-header.png';

export const headers = [
  {
    image: resources_page_image,
    page_title: 'Resources & Publications',
    page_description: 'Latest company news and publications',
    className: 'header-container resources-page-image',
    key: 'resources'
  },
  {
    image: services_page_image,
    page_title: 'Services',
    page_description: '100% accuracy in typing is our quality metric',
    className: 'header-container services-page-image',
    key: 'services'
  },
  {
    image: kits_page_image,
    page_title: 'Kits',
    page_description: "We provide the highest standard of testing for our client's genetic test",
    className: 'header-container kits-page-image',
    key: 'kits'
  },
  {
    image: technology_page_image,
    page_title: 'Technology',
    page_description: 'Continuous quality improvement in sequencing technology and software analysis tools',
    className: 'header-container tech-page-image',
    key: 'technology'
  },
  {
    image: contact_page_image,
    page_title: 'Contacts',
    page_description: 'Get in touch',
    className: 'header-container contact-page-image',
    key: 'contact'
  },
  {
    image: service_page_image,
    page_title: '',
    page_description: '',
    className: 'header-container service-page-image',
    key: 'service'
  },
  {
    image: service_page_image,
    page_title: 'Privacy Policy',
    page_description: '',
    className: 'header-container service-page-image',
    key: 'policy'
  },
  {
    image: about_page_image,
    page_title: 'About Scisco Genetics',
    page_description: '',
    className: 'header-container about-page-image',
    key: 'about'
  },
  {
    image: about_page_image,
    page_title: 'Terms of Use',
    page_description: '',
    className: 'header-container about-page-image',
    key: 'terms'
  },
]