import './Kits.css';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const Kits = ({ kits }) => {
  const kit = kits.map((k, index) => {
    const highlights = k.highlights.map((h, index) => (
      <li className='highlight' key={index}>
        {h}
      </li>
    ));

    return (
      <div key={index} className={k.className}>
        <div className='kits-container'>
          <p className='background-num'>{k.background_num}</p>
          <h1 className='kit-title'>{k.name}</h1>
          <h2 className='kit-headline'>{k.headline}</h2>
          <div className='highlights-container'>
            <h4 className='highlights-title'>Highlights</h4>
            <ul className='kits-ul'>{highlights}</ul>
          </div>
          <div>
            <h4 className='kit-section-title'>Overview</h4>
            <section className='overview-container'>
              <div>
                <p className='overview-desc'>{k.overview_1}</p>
                <p className='overview-desc'>{k.overview_2}</p>                
              </div>

              <img
                className='kit-image'
                src={k.image}
                alt='kit example'
                rel='noreferrer'
              />
            </section>
          </div>
          <section className='downloads-container'>
            <a
              href={k.brochure}
              target='_blank'
              className='download'
              rel='noreferrer'
            >
              {k.doc_title} Product Brochure <FileDownloadOutlinedIcon />
            </a>
            <a
              href={k.insert}
              target='_blank'
              className='download'
              rel='noreferrer'
            >
              {k.doc_title} Product Insert <FileDownloadOutlinedIcon />
            </a>
            <a
              href={k.protocol}
              target='_blank'
              className='download'
              rel='noreferrer'
            >
              {k.doc_title} Product Protocol <FileDownloadOutlinedIcon />
            </a>
          </section>
        </div>
      </div>
    );
  });
  return <div className=''>{kit}</div>;
};

export default Kits;
