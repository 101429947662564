import './HomePage.css';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@emotion/react';
import { Button } from '@mui/material';
import pacbio from '../../images/homepage/pacbio.png';
import ashi_logo from '../../images/homepage/ashi.png';
import clia_logo from '../../images/homepage/clia.png';
import PublicationsCard from '../../components/PublicationsCard/PublicationsCard';
import ServiceHomePageCard from '../../components/ServiceHomePageCard/ServiceHomePageCard';
import orange_line from '../../images/orange-line.png';

const HomePage = ({ service_cards, publications, theme }) => {
  return (
    <>
      <Helmet>
        <title>Scisco Genetics - Genotyping the Immune System</title>
        <meta
          name='description'
          content='Scisco Genetics provides NGS genotyping service and kits for HLA, KIR, FcGR and more.  Here you will find an introduction into products and services that Scisco Genetics provides.'
        />
      </Helmet>
      <header className='home-page-header'>
        <h1 className='home-page-title'>
          High resolution genotyping of complex immune regions
        </h1>
        <h4 className='main-description'>
          Fast and accurate high resolution genotyping of complex immune regions
          is critical to improving transplant outcomes and understanding disease
          associations.
        </h4>
        <ThemeProvider theme={theme}>
          <Button
            href='/contact'
            className='contact-button'
            color='primary'
            variant='contained'
            sx={{ borderRadius: '30px', fontWeight: 700 }}
          >
            {' '}
            Contact us
          </Button>
        </ThemeProvider>
      </header>

      <div className='homepage'>
        <section className='services-container'>
          <div className='services-info'>
            <h5 className='section-title'>
              Our Products and services{' '}
              <img
                src={orange_line}
                className='orange-line'
                alt='decorative line'
              />{' '}
            </h5>
            <p className='services-heading'>
              Scisco Genetics Inc. is committed to providing the highest
              possible standard of testing to our clients
            </p>
            <p className='services-description'>
              Therefore, we have implemented a quality assurance program to
              ensure continuous improvement in our performance and accurate and
              reliable test results for our customers. Given good quality DNA
              samples, our quality metric is to obtain 100% accuracy in typing.
            </p>
          </div>
          <ServiceHomePageCard service_cards={service_cards} />
        </section>

        <section className='ngs-container'>
          <div className='ngs'>
            <div className='ngs-info'>
              <h5 className='section-title'>
                NGS{' '}
                <img
                  src={orange_line}
                  className='orange-line'
                  alt='decorative line'
                />{' '}
              </h5>
              <h4 className='ngs-title titles'>Next Generation Sequencing</h4>
            </div>
            <p className='ngs-desc'>
              Scisco Genetics has developed a system using the Illumina Miseq,
              which is based on a sequencing-by-synthesis approach utilizing
              fluorescently labeled reversible terminator nucleotides. After
              assay specific amplification, samples are tagged with unique
              indexes and pooled together and applied to the MiSeq device where
              they are amplified as individual clusters and sequenced using
              universal sequencing primers. The result is several million reads
              that can be analyzed using ScisCloud™ to report unambiguous HLA
              allele types and KIR haplotypes for scores to hundreds of
              individual samples simultaneously.
            </p>
          </div>
          <img className='tech-background' src={pacbio} alt='tech' />
        </section>

        <section className='cert-container'>
          <div className='cert'>
            <img src={clia_logo} className='logos no-mobile' alt='clia logo' />
            <p className='cert-desc'>
              Our quality assurance program has been assessed and approved by
              the American Society for Histocompatibility and Immunogenetics
              (ASHI) and the Clinical Laboratory Improvements Amendments (CLIA).
            </p>
            <img src={clia_logo} className='logos mobile' alt='clia logo' />
            <img src={ashi_logo} className='logos' alt='ashi logo' />
          </div>
        </section>

        <section className='company-news-container'>
          <div className='company-news-info'>
            <h5 className='section-title'>
              Company News{' '}
              <img
                src={orange_line}
                className='orange-line'
                alt='decorative line'
              />{' '}
            </h5>
            <h4 className='titles'>Latest company news and publications</h4>
          </div>
          <div className='publications-container'>
            <PublicationsCard publications={publications.slice(0, 3)} />
            <ThemeProvider theme={theme}>
              <Button
                href='/resources_&_publications/#publications'
                className='view-pubs-button'
                color='primary'
                variant='contained'
                sx={{ borderRadius: '30px', fontWeight: 700 }}
              >
                View all publications
              </Button>
            </ThemeProvider>
          </div>
        </section>
      </div>
    </>
  );
};

export default HomePage;
