import './ContactPage.css';
import { Helmet } from 'react-helmet';
import { orange } from '@mui/material/colors';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Header from '../../components/header/Header';
import OrderCard from '../../components/OrderCard/OrderCard';
import ContactForm from '../../components/ContactForm/ContactForm';
import map from '../../images/map.png';

const ContactPage = ({ headers, ordering, theme }) => {
  return (
    <div>
      <Helmet>
        <title>Contact Scisco Genetics</title>
        <meta
          name='description'
          content='Reach out to Scisco Genetics for any questions or to get a quote for service or kits'
        />
      </Helmet>
      <Header header={headers.filter((header) => header.key === 'contact')} />
      <div className='contact-container'>
        <section>
          <h1 className='main-header'>Scisco Genetics</h1>
          <p className='about'>
            Scisco Genetics Inc. is committed to providing the highest possible
            standard of testing to our clients. Therefore, we have implemented a
            quality assurance program to ensure continuous improvement in our
            performance and accurate and reliable test results for our
            customers.
          </p>
          <div>
            <section className='contact-section'>
              <div className='icon'>
                <LocationOnIcon sx={{ color: orange[700] }} />
              </div>
              <a
                className='contact-info'
                href='https://goo.gl/maps/ejxQXfsv8g96uuLPA'
                target='_blank'
                rel='noreferrer'
              >
                1551 Eastlake Ave E Suite 100, Seattle, WA 98102
              </a>
            </section>
            <section className='contact-section'>
              <div className='icon'>
                <PhoneInTalkIcon sx={{ color: orange[700] }} />
              </div>
              <a href='tel: 888-827-9603' className='contact-info'>
                +1.888.827.9603
              </a>
            </section>
            <section className='contact-section'>
              <div className='icon'>
                <EmailIcon sx={{ color: orange[700] }} />
              </div>
              <a
                href='mailto: info@sciscogenetics.com'
                className='contact-info'
              >
                info@sciscogenetics.com
              </a>
            </section>
            <section className='contact-section'>
              <div className='icon'>
                <CalendarMonthIcon sx={{ color: orange[700] }} />
              </div>
              <p className='contact-info'>Mon-Fri: 9AM-5PM PST</p>
            </section>
          </div>
        </section>
        <a
          href='https://goo.gl/maps/ejxQXfsv8g96uuLPA'
          target='_blank'
          rel='noreferrer'
          className='map'
        >
          <img src={map} className='map' alt='map' />
        </a>
      </div>
      <div className='ordering-container' id='order'>
        <h2 className='secondary-header'>Lab and Kit Ordering</h2>
        <OrderCard ordering={ordering} />
      </div>
      <section className='contact-form'>
        <ContactForm theme={theme} />
      </section>
    </div>
  );
};

export default ContactPage;
