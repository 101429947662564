import './Header.css';

const Header = ({ header }) => {
  return (
    <>
      <header className={header[0].className}>
        <section className='header-details'>
          <h4 className='header-title'>{header[0].page_title}</h4>
          <h1 className='header-info'>{header[0].page_description}</h1>
        </section>
      </header>
    </>
  )
};

export default Header;