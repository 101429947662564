import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import KitProcess from "../../components/KitProcess/KitProcess";
import Kits from "../../components/Kits/Kits";
import "./KitsPage.css";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Videos from "../../components/Videos/Videos";


const KitsPage = ({ headers, kits }) => {
	return (
		<>
			<Helmet>
				<title>Scisco Genetics - Typing Kits for HLA Typing, KIR Typing, FcGR typing and Chimerism Testing</title>
				<meta
					name="description"
					content="Typing Kits for HLA, Chimerism, KIR and FcR-IGHG make Scisco Gentics technology available to any lab - HLA typing kits, KIR typing Kits, Chimerism typing kits"
				/>
			</Helmet>
			<Header header={headers.filter((header) => header.key === "kits")} />
			<Kits kits={kits} />
			<KitProcess />
      <Videos />

			{/* <Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
					Accordion 1
				</AccordionSummary>
				<AccordionDetails>
					<div className="video">
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/6yB9WuY08cU?si=tctxGRsReHOcIXKC"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerpolicy="strict-origin-when-cross-origin"
							allowfullscreen
						></iframe>
					</div>
				</AccordionDetails>
			</Accordion> */}
		</>
	);
};

export default KitsPage;
