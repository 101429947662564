import hla_image from '../images/services/service-1.png';
import kir_image from '../images/services/service-3.png';
import micab_image from '../images/services/service-2.png';
import fcgr_image from '../images/services/service-5.png';
import chimerism_image from '../images/services/service-4.png';
import tvd_image from '../images/services/service-6.png';
import hla_fig_1 from '../images/services/hla/hla-fig-1.png';
import hla_fig_2 from '../images/services/hla/hla-fig-2.png';
import kir_fig_1 from '../images/services/kir/kir-fig-1.png';
import kir_fig_2 from '../images/services/kir/kir-fig-2.png';
import kir_fig_3 from '../images/services/kir/kir-fig-3.png';
import kir_fig_4 from '../images/services/kir/kir-fig-4.png';
import kir_fig_5 from '../images/services/kir/kir-fig-5.png';
import mic_fig_1 from '../images/services/mic/mic-fig-1.png';
import fcr_fig_1 from '../images/services/fcr/fcr-fig-1.png';
import sample_hla_report from '../files/sample-reports/sample-hla-report.pdf';
import sample_kir_report from '../files/sample-reports/sample-kir-report.pdf';
import sample_micab_report from '../files/sample-reports/sample-micab-report.pdf';
import sample_fcr_report from '../files/sample-reports/sample-fcr-report.pdf';
import hla_service_pdf from '../files/service/SGI-HLA.pdf';
import kir_service_pdf from '../files/service/SGI-KIR.pdf';
import micab_service_pdf from '../files/service/SGI-MICAB.pdf';
import fcr_service_pdf from '../files/service/SGI-FcR-IGHG.pdf';
import hla_mini_fig from '../images/services/hla/hla-mini-fig.png';
import kir_mini_fig from '../images/services/kir/kir-mini-fig.png';
import mic_mini_fig from '../images/services/mic/mic-mini-fig.png';
import fcr_mini_fig from '../images/services/fcr/fcr-mini-fig.png';
import chimerism_mini_fig from '../images/services/chimerism/chimerism-mini-fig.png';
import tvd_mini_fig from '../images/services/tvd/tvd-mini-fig.png';

export const services = [

  {
    key: 1,
    nickname: 'hla',
    mini_img: hla_mini_fig,
    services_page_image: hla_image,
    services_page_title: 'human leukocyte antigen (HLA)',
    headline:
      'High resolution genotyping of HLA class I and class II genes using ScisGo-HLA',
    services_page_description:
      'ScisGo-HLA-v6 is a next generation sequencing (NGS) technology that yields high resolution genotypes for all eleven HLA-class I (CI) and class II (CII) loci. The NGS data cover all exon and sufficient intron sequences to yield 3-field typing with detection of all known null alleles. Ambiguities are minimized with zero allele ambiguities and rare diploid ambiguities encountered at the 3-field HLA allele level. We offer range of standard and customized data reporting options.',
    primary_description:
      'ScisGo-HLA-v6 is a next generation sequencing (NGS) technology that yields high resolution genotypes for all eleven HLA-class I (CI) and class II (CII) loci. The NGS data cover all exon and sufficient intron sequences to yield 3-field typing with detection of all known null alleles. Ambiguities are minimized with zero allele ambiguities and rare diploid ambiguities encountered at the 3-field HLA allele level. We offer range of standard and customized data reporting options.',
    sample_report: sample_hla_report,
    link: '/services/1',
    className: 'service-card reverse-row',
    className2: 'service-image-div',
    title: 'ScisGo™ HLA Typing',
    images: [
      {
        figure: hla_fig_1,
        figure_desc:
          'HLA-A, B, C gene structure and NGS coverage using ScisGo-HLA-v6. The cartoon displays the intron (lines between exons) and exon (blue boxes) structure of HLA class I genes. ScisGo-HLA-v6 yields phased data from all exons and introns sufficient to yield 3-field types for HLA-A, B, C in a single reaction set. ',
      },
      {
        figure: hla_fig_2,
        figure_desc:
          'HLA-DR, DQ, DP gene structure and NGS coverage using ScisGo-HLA-v6. The cartoon displays the intron (lines between exons) and exon (red boxes) structure of HLA class II genes.ScisGo-HLA-v6 yields phased data from all exons and introns sufficient to yield 3-field types for the eight CII genes HLA-DRB1345, DQA1, DQB1, DPA1, DPB1 in a single reaction set. ',
      },
    ],
    service_pdf: hla_service_pdf,
  },
  {
    key: 2,
    nickname: 'kir',
    mini_img: kir_mini_fig,
    services_page_image: kir_image,
    services_page_title: 'Killer Ig-Like Receptor (KIR)',
    headline: 'KIR haplotype and allele typing with ScisGo-KIR-v3',
    services_page_description:
      'ScisGo-KIR-v3 is a system that allows for the determination of gene copy number and haplotype structure as well as targeted KIR gene allele determination. Gene copy number is determined by comparisons of distinct KIR exon/loci read numbers. Haplotypes are deduced from gene copy number according to established reference haplotypes.  Allele typing includes exons 1-8 for a subset of KIR loci.',
    primary_description:
      'ScisGo-KIR-v3 is a system that allows for the determination of gene copy number and haplotype structure as well as targeted KIR gene allele determination. Gene copy number is determined by comparisons of amplicon-derived read numbers between distinct KIR exon/ loci. Haplotypes are deduced from gene copy number according to established reference haplotypes. Allele determination is made by mapping of individual exon sequences as illustrated in Fig. 1 to the IMGT/KIR reference database supplemented with the Scisco Genetics 1000 genomes reference data resource (Fig. 2).',
    secondary_description:
      'ScisGo-KIR-v3 was applied to obtain KIR haplotype and partial resolution allele types on 2,542 of the 1000 genomes samples representing 25 ancestral populations. This level of sequencing leaves a level of ambiguity in the allele data due to polymorphism in unsampled exons that can be used as is or if needed solved through long-read genomic sequencing. Haplotypes were deduced from exon sequence reads (relative frequencies). The most significant finding from the 1000 genomes data is the variation of haplotype frequency among different populations (Fig. 2), including a total of 16 rare haplotypes that our system predicts have an as yet undetermined structure.',
    sample_report: sample_kir_report,
    link: '/services/2',
    className: 'service-card',
    className2: 'reverse-image',
    title: 'ScisGo-KIR-v3',
    images: [
      {
        figure: kir_fig_1,
        figure_desc:
          'Figure 1. Targeted short-read sequencing of KIR loci. Exons are targeted in multiplex amplicon reactions as indicated in the color key using inter-gene amplicons that span exons from multiple genes.',
      },
      {
        figure: kir_fig_2,
      },
      {
        figure: kir_fig_3,
        figure_desc:
          'Figure 2a. Allele frequencies for KIRDS4 and KIR2DL4 summarized for the population groups. KIR allele typing is available for most KIR genes.',
      },
      {
        figure: kir_fig_4,
        figure_desc:
          'Figure 2b. KIR haplotype and allele frequencies in the twenty-five 1000 genomes populations. (top) gene content diagrams of 13 common KIR haplotypes measured in the bar graphs.',
      },
      {
        figure: kir_fig_5,
        figure_desc:
          'Figure 2c. Showing the percentage of each haplotype structure within each of twenty-five 1000 genomes populations according to the color coding immediately beneath the graphs. Haplotype designation ‘other’ includes 68 rare haplotypes including 16 of unknown structure. ',
      },
    ],
    service_pdf: kir_service_pdf,
  },
  {
    key: 3,
    nickname: 'micab',
    mini_img: mic_mini_fig,
    services_page_image: micab_image,
    services_page_title: 'MHC Class I chain-related A & B (MICAB)',
    headline:
      'High resolution genotyping of the MICA and MICB genes using ScisGo-MICAB-v3',
    services_page_description:
      'ScisGo-MICAB-v3 is a next generation sequencing (NGS) technology that yields high resolution genotypes for MICA and MICB. The NGS data cover all exon and sufficient intron sequences to yield 2-field typing for all loci including the detection of all known null alleles.',
    primary_description:
      'Scisco Genetics offers MICA and MICB typing at high resolution including all exon sequences. The MICA and MICB loci are typed with next generation sequencing (NGS) technology to yield high resolution genotypes. Our ScisGo-MICAB-v3 is a workhorse yielding 2-field types for both loci. The NGS data cover all exon and sufficient intron sequences to yield 2-field typing for all loci including the detection of all known null alleles. ',
    title: 'ScisGo-MICAB-v3',
    images: [
      {
        figure: mic_fig_1,
        figure_desc:
          'Figure 1. MICA and MICB gene structures and NGS coverage using ScisGoMICAB-v3. The cartoon displays the intron (lines between exons) and exon (green boxes) structure of the MICA and MICB genes. ScisGo-MICAB-v6 yields phased data from all exon and sufficient intron sequence to yield 2-field types including all known null alleles for MICA and MICB in a single reaction set. ',
      },
    ],
    sample_report: sample_micab_report,
    link: '/services/3',
    className: 'service-card reverse-row',
    className2: 'service-image-div',
    service_pdf: micab_service_pdf,
  },
  {
    key: 4,
    nickname: 'fcr',
    mini_img: fcr_mini_fig,
    services_page_image: fcgr_image,
    services_page_title: 'Fc receptor + Immunoglobulin heavy G ',
    headline:
      'High resolution genotyping of FcR and IGHG for diagnostics and therapeutics with ScisGo-FcR/IGHG-v2',
    services_page_description:
      'ScisGo-FcR-IGHG technology utilizes existing data sets at the IMGT and the NCBI genbank repository supplemented with long range sequencing of 1000 genome samples from 25 populations providing a comprehensive database of FcR and IGHG polymorphism. This enhanced proprietary database allows the majority of variants detected by the short-read NGS assay to be phased accordingly through mapping back to the database.',
    primary_description:
      'ScisGo-FcR-IGHG technology utilizes existing data sets at the IMGT and the NCBI genbank repository supplemented wit long range sequencing of 1000 genome samples from 25 populations providing a comprehensive database of FcR and IGHG polymorphism. This enhanced database allows the majority of variants detected by the short-read NGS assay to be phased accordingly through mapping back to the database. Our current targeting probes for the FcR genes include a panel of variants that have been previously characterized functionally (Table 1). The four IgG encoding genes are targeted at the CH1, CH2 and CH3 regions collected from 3 corresponding amplicons (Fig. 1) and phase between CH regions is determined through database lookup using enhanced long-read data sets from 1000 genomes samples. This FcR/IGHG short-read NGS multiplexing system uses the same technology as that currently deployed for HLA and post-transplant chimerism now in active use in clinical laboratories (ScisGo-HLA-v6, ScisGo-CHIM-v3). ',
    title: 'ScisGo-FcR/IGHG-v2',
    images: [
      {
        figure: fcr_fig_1,
        figure_desc:
          'Figure 1. Sequence diversity of the Human immunoglobulin constant heavy G chain (IGHG) (Fcγ) (GM) gene C1, C2, C3 regions. The IGHG gene is indicated to the left of each block of listed sequence variants with variable positions indicated. ',
      },
    ],
    sample_report: sample_fcr_report,
    link: '/services/4',
    className: 'service-card',
    className2: 'reverse-image',
    service_pdf: fcr_service_pdf,
  },
  {
    key: 5,
    nickname: 'chimerism',
    mini_img: chimerism_mini_fig,
    services_page_image: chimerism_image,
    services_page_title: 'chimerism',
    headline:
      'High sensitivity multi-donor chimerism detection ',
    services_page_description:
      'ScisGo Chimerism MD is designed to perform chimerism testing (engraftment analysis) on sample DNAs.  Chimerism detection can be achieved for up to 3 independent donors in recipient DNA from post-transplant or other immunotherapy samples.',
    primary_description:
      '',
    title: 'Chimerism',
    images: [
      {
        figure: '',
        figure_desc:
          '',
      },
    ],
    sample_report: '',
    link: '/services/5',
    className: 'service-card reverse-row',
    className2: 'service-image-div',
    service_pdf: '',
  },
  {
    key: 6,
    nickname: 'targeted-variation',
    mini_img: tvd_mini_fig,
    services_page_image: tvd_image,
    services_page_title: 'Targeted variation detection',
    headline:
      'Human genetic variation detection for defined targets',
    services_page_description:
      'This service provides design and implementation of variant detection for any customer-defined target in the human genome.  Design starts with customer selected rsID number(s) and  reporting includes sample data and reference frequency information form 5 major ancestral populations.',
    primary_description:
      '',
    title: 'Targeted variation detection',
    images: [
      {
        figure: '',
        figure_desc:
          '',
      },
    ],
    sample_report: '',
    link: '/services/6',
    className: 'service-card',
    className2: 'reverse-image',
    service_pdf: '',
  },
];
