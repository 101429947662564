import './TechnologyPage.css';
import { Helmet } from 'react-helmet';
import Header from '../../components/header/Header';
import background_image from '../../images/tech-page-background.png';
import human_background from '../../images/human-background.png';
import hla_table from '../../images/techpage/hla-table.png';
import image1 from '../../images/techpage/tech1.png';
import image2 from '../../images/techpage/tech2.png';
import hla_nomenclature from '../../images/techpage/hla-nomenclature.png';
import kir_table from '../../images/techpage/kir-table.png';
import kir_table_2 from '../../images/techpage/kir-table-2.png';
import HlaTable from '../../components/HlaTable/HlaTable';

const TechnologyPage = ({ headers }) => {
  return (
    <div>
      <Helmet>
        <title>Scisco Genetics Gene Sequencing Technology</title>
        <meta
          name='description'
          content='Scisco Genetics has developed a high resolution typing system based on Next Generation Sequencing (NGS) that results in several million reads that can be analyzed using ScisCloud™ to report unambiguous HLA allele types and KIR haplotypes for scores to hundreds of individual samples simultaneously.  Scisco has developed high accuracy typing kits that are simple to learn, and easy to use: HLA typing kits, KIR typing Kits, Chimerism typing kits'
        />
      </Helmet>
      <Header
        header={headers.filter((header) => header.key === 'technology')}
      />

      <div className='tech-container'>
        <section className='technology-section'>
          <img
            src={background_image}
            className='background-image'
            alt='helix background'
          />
          <h2 className='tech-title'>Next Generation Sequencing (NGS)</h2>
          <p className='tech-desc'>
            High resolution HLA typing using current sequencing-based methods
            requires time consuming and expensive strategies to distinguish
            between ambiguous alleles or allele combinations. Next Generation
            Sequencing (NGS) approaches have been developed within the last few
            years that provide allele phase and unambiguous pairwise
            combinations of alleles. These technologies can reduce the cost of
            high resolution typing to that of commonly used low or
            medium-resolution methods while maintaining equivalent simplicity in
            methods and application.
          </p>
          <p className='tech-desc'>
            Scisco Genetics has developed a system using the Illumina Miseq,
            which is based on a sequencing-by-synthesis approach utilizing
            fluorescently labeled reversible terminator nucleotides. After assay
            specific amplification, samples are tagged with unique indexes and
            pooled together and applied to the MiSeq device where they are
            amplified as individual clusters and sequenced using universal
            sequencing primers. The result is several million reads that can be
            analyzed using ScisCloud™ to report unambiguous HLA allele types and
            KIR haplotypes for scores to hundreds of individual samples
            simultaneously.
          </p>
        </section>

        <section className='technology-section'>
          <h2 className='tech-title'>HLA Complex</h2>
          <p className='tech-desc'>
            The HLA complex, also commonly known as the human major
            histocompatibility complex (MHC), comprises a 4 Mb region on
            chromosome 6p21.3 that includes over 200 genes. Greater than 40% of
            these genes encode proteins involved in immune functions that play
            critical roles in defense against infectious disease and cancer and
            in susceptibility and resistance to autoimmune diseases. Certain
            highly polymorphic HLA genes function as histocompatibility barriers
            and are critical markers for donor-recipient matching in bone marrow
            transplantation.
          </p>
          <p className='tech-desc'>
            The HLA gene complex is subdivided into the class I, class II, and
            class III regions (Figure 1 below). The class I and II genes encode
            molecules that bind and present peptide fragments to T lymphocytes
            via the antigen binding groove of the mature HLA cell surface
            protein. Class I molecules are found on most cell types and present
            endogenous peptides derived from the intracellular environment of
            cancer cells, infected cells (viruses), or cells that are damaged in
            other ways. The bound peptide in the Class I molecules is presented
            to CD8+ T-cells, which kill the infected/ damaged cells. Class II
            molecules are mainly found on Antigen Presenting Cells and present
            antigen to CD4+ T helper cells. The Class II molecules sample the
            extracellular environment and thus present peptides, such as
            bacteria, derived from outside the cell. Both HLA Class I and Class
            II genes are fundamental to the body’s recognition of self and
            non-self. The class III region contains genes for cytokines and the
            components of the complement system that also play important roles
            in the immune response.
          </p>
          <div className='image-section'>
            <img src={hla_table} className='image' alt='chromosome 6' />
            <p className='table-desc'>
              *Allele numbers from HLA, Nomenclature@hla.alleles.org, Sept. 2018
            </p>
            <p className='table-desc'>
              Specific HLA alleles are associated with susceptibility and
              resistance to autoimmune and infectious diseases. Disparity
              between donor and recipient HLA-A,-B,-C, DR, DQA, DQB and DPA and
              DPB alleles impacts outcome in both bone marrow and solid organ
              transplantation.
            </p>
          </div>
          <section className='hla-tables'>
            <p className='table-desc'>
              The HLA complex is the most polymorphic region in the entire human
              genome with close to 18,000 different HLA alleles characterized
              thus far. The list continues to expand rapidly as increasing
              numbers of new alleles continue to be identified. For the most
              updated list visit the{' '}
              <a
                href='https://www.ebi.ac.uk/ipd/imgt/hla/'
                className='orange-links'
                target='_blank'
                rel='noreferrer'
              >
                IMGT/HLA database.
              </a>
            </p>
            <HlaTable />
            <br />
            <p className='table-desc'>
              Specific HLA alleles are associated with susceptibility and
              resistance to autoimmune and infectious diseases. Disparity
              between donor and recipient HLA-A,-B,-C, DR, DQA, DQB and DPA and
              DPB alleles impacts outcome in both bone marrow and solid organ
              transplantation.
            </p>
          </section>
        </section>

        <section className='hla-section'>
          <div className='technology-section'>
            <h2 className='tech-title'>HLA Nomenclature</h2>
            <div className='image-section'>
              <img
                src={hla_nomenclature}
                className='image'
                alt='hla nomenclature'
              />
            </div>
          </div>
        </section>

        <section className='technology-section'>
          <h2 className='tech-title'>HLA Typing</h2>
          <img
            src={human_background}
            className='background-image-reverse'
            alt='human background'
          />
          <div className='tech-section'>
            <p className='tech-desc'>
              HLA typing initially identified HLA cell surface antigen
              specificities through serological and cellular typing methods.
              With the advent of the polymerase chain reaction (PCR), DNA based
              HLA typing technology quickly developed and was widely implemented
              in clinical and research laboratories. Early HLA DNA typing
              systems involved panels of sequence specific oligonucleotide
              primer pairs and panels of sequence specific oligonucleotide
              probes to assess specific polymorphisms and type for the alleles
              known at the time of the typing. DNA sequencing-based typing
              became the “gold standard” for high resolution HLA typing with the
              development of fluorescent-dye labeled sequencing of PCR amplicons
              using automated genetic analysis equipment coupled with HLA
              software analysis products.
            </p>
            <img src={image1} className='hla-image' alt='test tubes' />
          </div>
          <div className='tech-section-reverse'>
            <img src={image2} className='hla-image' alt='dna' />
            <p className='tech-desc'>
              The widespread application of HLA DNA-based typing continues to
              reveal the extraordinary polymorphism of the HLA system, with a
              steady stream of sequences reported for new allele names (see the
              <a
                href='https://www.ebi.ac.uk/ipd/imgt/hla/'
                className='orange-links'
                target='_blank'
                rel='noreferrer'
              >
                &nbsp;IMGT/HLA database
              </a>
              ). One result of the explosion in discovery of new HLA allele
              sequences has been the constantly increasing level of ambiguous
              typing results in heterozygous individuals. The characteristic
              sharing of polymorphic sequence cassettes among many HLA alleles
              frequently generates ambiguous typing due to an inability to
              assign the phase of the polymorphisms without separating the
              alleles. Laboratories that require high resolution typing,
              including those with current sequencing-based HLA typing systems,
              have had to develop complex, time-consuming and expensive
              strategies for separating the HLA allele sequences in heterozygous
              specimens. The advent of next generation sequencing technology
              obviates heterozygote ambiguities. NGS sequencing of individual
              PCR transcripts enables us to produce fully phased high resolution
              HLA-A, B, C, DRB1/3/4/5, DQA1, DQB1, DPA1 and DPB1 typing in a
              single pass typing system.
            </p>
          </div>
        </section>

        <section className='technology-section'>
          <h2 className='tech-title'>Killer Cell Ig-like Receptors (KIR)</h2>
          <div className='kir-section'>
            <p className='kir-desc'>
              KIR genes encode inhibitory receptors that reside on the surface
              of natural killer (NK) cell. NK cells are an important part of the
              innate immune system, responsible for defense against infected or
              cancer cells. The interaction of KIR receptors with HLA class I
              molecules regulates NK cell function by distinguishing between
              normal cells and infected or cancer cells.
            </p>
            <p className='kir-desc'>
              The KIR locus is located on chromosome 19q13.4 within the
              leukocyte receptor complex (LRC) and is highly variable with
              regard to both gene content and allelic polymorphism. KIR genes
              are organized into the major haplotype groups A and B based on
              gene content (see figure below). Haplotype A is constant in gene
              content, consisting of 5 inhibitory genes and 1 activating gene,
              while haplotype B contains a variable number of inhibitory and
              activating genes.
            </p>
          </div>
        </section>

        <section className='image-section technology-section'>
          <img src={kir_table} className='image' alt='chromosome 19' />
          <img src={kir_table_2} className='image' alt='kir table' />
          <p className='table-desc'>
            A total of 15 human KIR genes and 2 pseudogenes have been
            identified, separated into centromeric and telomeric regions by the
            framework genes KIR3DP1 and KIR2DL4. Centromeric motifs can
            recombine freely with telomeric motifs, leading to a diverse number
            of haplotypes.
          </p>
          <p className='table-desc'>
            Recently, we identified 7 major gene content haplotypes, each
            representing different combinations of 4 centromeric and 2 telomeric
            gene motifs. After examining a further 9,024 chromosomes, we
            identified 37 rare gene-content haplotypes that varied from the 7
            major haplotypes by gene deletions, insertions, and hybridizations
            (shown in figure above).
          </p>
        </section>
      </div>
    </div>
  );
};

export default TechnologyPage;
