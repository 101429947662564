import './ContactForm.css';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useRef, useState } from 'react';

const ContactForm = ({ theme }) => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  const [message, setMessage] = useState(null);
  const [disableSubmit,setDisableSubmit] = useState(true);

  const handleChange = (evt) => {
    const target = evt.target;
    let newFormData = { ...formData, [target.name]: target.value };
    setFormData(newFormData);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'contact_service',
        'contact_form',
        form.current,
        '3qAcb3mvnuW0UDQ2V'
      )
      .then(
        (result) => {
          setFormData({
            user_name: '',
            user_email: '',
            message: '',
          });
          setMessage(
            <p className='message-sent'>Message sent successfully!</p>
          );
        },
        (error) => {
          alert(error.text);
        }
      );
  };


  return (
    <div className='contact-form-container'>
      <h1>Ask us a question</h1> 
      <form onSubmit={sendEmail} ref={form} autoComplete='off'>
        <input
          name='user_name'
          value={formData.user_name}
          className='name-input input'
          placeholder='Name'
          required={true}
          onChange={handleChange}
        />
        <input
          name='user_email'
          value={formData.user_email}
          type='email'
          className='email-input input'
          placeholder='Email'
          required={true}
          onChange={handleChange}
        />
        <textarea
          name='message'
          value={formData.message}
          className='message-input input'
          placeholder='Write a message'
          required={true}
          onChange={handleChange}
        />
        <ReCAPTCHA sitekey={'6Lfh5b8kAAAAAJ1g-UBNq31DQW81oKa16rGGdzV5'} onChange={() => setDisableSubmit(false)} />
        <ThemeProvider theme={theme}>
          <Button
            type='submit'
            target='_blank'
            className='submit'
            color='primary'
            variant='contained'
            sx={{ borderRadius: '30px', fontWeight: 700 }}
            disabled={disableSubmit}
          >
            {' '}
            Send us a message
          </Button>
        </ThemeProvider>
      </form>
      {message}
    </div>
  );
};

export default ContactForm;
