import './HlaTable.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { grey, orange } from '@mui/material/colors';

const HlaTable = () => {
  function createData(gene, alleles) {
    return { gene, alleles };
  }

  const rows = [
    createData('A', 4340),
    createData('B', 5212),
    createData('C', 3930),
    createData('E', 27),
    createData('F', 31),
    createData('G', 61),
  ];

  const second_rows = [
    createData('DPA1', 67),
    createData('DPB1', 1014),
    createData('DQA1', 95),
    createData('DQB1', 1259),
    createData('DRB', 2593),
  ];

  return (
    <div className='hla-tables-container'>
      <TableContainer component={Paper} sx={{ margin: '10px' }}>
        <Table aria-label='caption table'>
          <caption>
            *allele numbers from{' '}
            <a
              href='https://hla.alleles.org/nomenclature/index.html'
              className='orange-links'
              target='_blank'
              rel="noreferrer"
            >
              HLA Nomenclature
            </a>
            , Sept. 2018
          </caption>
          <TableHead>
            <TableRow
              sx={{
                th: {
                  backgroundColor: orange[600],
                  fontWeight: 700,
                  color: 'white',
                },
              }}
            >
              <TableCell align='center'>GENE</TableCell>
              <TableCell align='center'>ALLELES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.gene}
                sx={{
                  '&:nth-of-type(even) td': { backgroundColor: grey[100] },
                }}
              >
                <TableCell component='td' scope='row' align='center'>
                  {row.gene}
                </TableCell>
                <TableCell align='center'>{row.alleles}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        component={Paper}
        sx={{ margin: '10px', height: 'fit-content' }}
      >
        <Table sx={{}} aria-label='caption table'>
          <TableHead>
            <TableRow
              sx={{
                th: {
                  backgroundColor: orange[600],
                  fontWeight: 700,
                  color: 'white',
                },
              }}
            >
              <TableCell align='center'>GENE</TableCell>
              <TableCell align='center'>ALLELES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {second_rows.map((row) => (
              <TableRow
                key={row.gene}
                sx={{
                  '&:nth-of-type(even) td': { backgroundColor: grey[100] },
                }}
              >
                <TableCell component='td' scope='row' align='center'>
                  {row.gene}
                </TableCell>
                <TableCell align='center'>{row.alleles}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HlaTable;
