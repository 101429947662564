import hla_typing_pdf from '../files/publications/HLA-typing.pdf';
import igs_pdf from '../files/publications/igs.pdf';
import fcgr_rv144_pdf from '../files/publications/fcgr-rv144.pdf';
import hla_ngs_pdf from '../files/publications/hla-ngs.pdf';
import kir_structures_pdf from '../files/publications/kir-structures.pdf';
import nhp_pdf from '../files/publications/NHP-immune-response.pdf';
import kir_haplotypes_pdf from '../files/publications/kir-haplotypes.pdf';
import hla_polymorphism_pdf from '../files/publications/hla-polymorphism.pdf';

export const publications = [
  {
    journal_name: 'HLA, Sep 2019',
    title:
      'Comparison of sequence-specific oligo-nucleotide probe vs NGS for HLA-A, B, C, DRB1, DRB3/B4/B5, DQA1, DQB1, DPA1, and DPB1 typing',
    description:
      'We compare a NGS technology with SSOP for accuracy, effort, turnaround time, and level of resolution for genotyping of 11 HLA loci among 289 specimens from five clinical laboratories. With few exceptions, SSOP generated ambiguous results while NGS provided unambiguous three-field allele assignments. This study provides compelling evidence that, although not viable for STAT typing of deceased donors, a single-pass NGS HLA typing method has direct application for solid organ transplantation.',
    link: hla_typing_pdf,
  },
  {
    journal_name: 'Human Immunology, Dec 2015',
    title:
      'An integrated genotyping approach for HLA and other complex genetic systems',
    description: 'The development and testing of the Integrated Genotyping System (IGS), which has been designed to specifically address the challenges associated with the adoption of NGS in clinical laboratories. The results are discussed with respect to their applications in the clinical setting and consequent potential for advancing precision medicine.',
    link: igs_pdf,
  },
  {
    journal_name: 'Journal of Clinical Investigation, Sep 2014',
    title:
      'FCGR2C polymorphisms associate with HIV-1 vaccine protection in RV144 trial',
    description: 'NGS typing identified 166 class II alleles, including 9 novel sequences with greater than 99% accuracy for DRB1 and DQB1 genotypes and elimination of diploid ambiguities through in-phase sequencing demonstrated the robust reliability of the NGS HLA genotyping reagents and analysis software employed in this study.',
    link: fcgr_rv144_pdf,
  },
  {
    journal_name: 'Human Immunology, Oct 2014',
    title:
      'NGS to determine HLA class II genotypes in a cohort of hematopoietic cell transplant patients and donors',
    description:
      'The phase III RV144 HIV-1 vaccine trial estimated vaccine efficacy (VE) to be 31.2%. This trial demonstrated that the presence of HIV-1–specific IgG-binding Abs to envelope (Env) V1V2 inversely correlated with infection risk, while the presence of Env-specific plasma IgA Abs directly correlated with risk of HIV-1 infection.',
    link: hla_ngs_pdf,
  },
  {
    journal_name: 'BMC Genomics, Feb 2013',
    title:
      'Recombinant structures expand and contract inter and intragenic diversification at the KIR locus',
    description:
      'A KIR haplotyping method that reports unambiguous combinations of KIR gene-content haplotypes. A total of 37 different gene content haplotypes were detected from 4,512 individuals and new sequence data was derived from haplotypes where the detailed structure was not previously available. These new structures suggest a number of specific recombinant events during the course of KIR evolution, and add to an expanding diversity of potential new KIR haplotypes derived from gene duplication, deletion, and hybridization.',
    link: kir_structures_pdf,
  },
  {
    journal_name: 'ILAR Journal, Jan 2013',
    title:
      'The Essential Detail: The Genetics and Genomics of the Primate Immune Response',
    description:
      'Knowledge about the variation in primate immune response loci can provide essential insights into relevant immune function. The essential detail contained within the genomics of the NHP immune response is now being assembled, and the realization of precise comparisons between NHP and human immune genomics is close at hand, further enhancing the NHP animal model in the search for effective treatments for human disease.',
    link: nhp_pdf,
  },
  {
    journal_name: 'PLoS One, Dec 29, 2010',
    title:
      'Different Patterns of Evolution in the Centromeric and Telomeric Regions of Group A and B Haplotypes of the Human Killer Cell Ig-Like Receptor Locus',
    description:
      'Phylogenetic analysis and divergence time estimates, point to the centromeric gene-content motifs that distinguish A and B haplotypes having emerged ~6 m.y.a., contemporaneously with the separation of human and chimpanzee ancestors. The telomeric motifs that distinguish A and B haplotypes emerged more recently, ~1.7 m.y.a., before the emergence of Homo sapiens. The results suggest the common ancestor of A and B haplotypes combined a B-like centromeric region with an A-like telomeric region.',
    link: kir_haplotypes_pdf,
  },
  {
    journal_name: 'Immunogenetics, Mar 2006',
    title:
      'HLA-E, HLA-F, and HLA-G polymorphism: genomic sequence defines haplotype structure and variation spanning the nonclassical class I genes',
    description:
      'Amplification of 6-7 kb of genomic sequence for each gene, including coding and intron sequences, ~2 kb of 5′ flanking promoter sequence, and 1 kb of 3′ flanking sequence. One or two PCR products were generated depending on the locus, amplified gen-omic DNA was directly sequenced to completion using a set of ~30 primers over each locus to yield contiguous sequence data from both strands. The results confirmed previously reported coding sequence variants, identified new allelic variants, and defined extensive variation in intron and flanking sequences.',
    link: hla_polymorphism_pdf,
  },
];