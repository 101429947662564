export const videos = [
  {
    srcLink: "https://www.youtube-nocookie.com/embed/6yB9WuY08cU?si=tctxGRsReHOcIXKC",
    title: "YouTube: Step 1: Adding DNA to Plates",
  },
  {
    srcLink: "https://www.youtube-nocookie.com/embed/VVLgn7IyKJA?si=Dl6m-EVpMeLCIvXq",
    title: "YouTube: Step 2: Adding S1 Buffer",
  },
  {
    srcLink: "https://www.youtube-nocookie.com/embed/65Sn8Y-QsTk?si=X1QXd7GbMLkPmjVq",
    title: "YouTube: Step 3: PCR Stage 1",
  },
  {
    srcLink: "https://www.youtube-nocookie.com/embed/XA7UX5QM4j8?si=KM4nINZqf0sv1IBv",
    title: "YouTube: Step 4: Adding S2 Buffer",
  },
  {
    srcLink: "https://www.youtube-nocookie.com/embed/V2u3hwmGJJM?si=II7w50gm7Y_noUfc",
    title: "YouTube: Step 5: PCR Stage 2",
  },
  {
    srcLink: "https://www.youtube-nocookie.com/embed/A2F75xeR-zY?si=hV6cNVkMFevi_qsX",
    title: "PCR Mat Cleaning Process",
  },
]