import './ServicePage.css';
import { useParams } from 'react-router-dom';
import FcrTable from '../../../components/FcrTable/FcrTable';
import Header from '../../../components/header/Header';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const ServicePage = ({ headers, services }) => {
  let { id } = useParams();
  let service = services.find((serv) => serv.key === parseInt(id));

  const table = () => {
    if (service.key === 4) {
      return <FcrTable />;
    }
  };

  const images = service.images.map((image) => {
    return (
      <div key={image.figure}>
        <img src={image.figure} className='figures' alt='figure'/>
        <p className='figure-description'>{image.figure_desc}</p>
      </div>
    )
  })
  

  return (
    <div>
      <Header header={headers.filter((header) => header.key === 'service')} />
      <div className='service-container'>
        <h1 className='title'>{service.title}</h1>
        <p className='headline'>{service.headline}</p>
        <p className='service-description'>{service.primary_description}</p>
        {images}
        {table()}
        <a href={service.service_pdf} target='_blank' rel="noreferrer" className='service-download'>Download this page as a pdf <FileDownloadOutlinedIcon /></a>
      </div>
    </div>
  );
};

export default ServicePage;
