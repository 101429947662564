import card_1 from '../../images/homepage/card-1.png';
import card_2 from '../../images/homepage/card-2.png';
import card_3 from '../../images/homepage/card-3.png';
import card_1_mini from '../../images/homepage/card-1-mini.png';
import card_2_mini from '../../images/homepage/card-2-mini.png';
import card_3_mini from '../../images/homepage/card-3-mini.png';

export const service_cards = [
  {
    title: 'Scisco Services™',
    description:
      'Scisco Genetics typing solutions for HLA, KIR, Fc, MIC AB and more.',
    link: '/services',
    main_image: card_1,
    mini_image: card_1_mini,
    className: 'card-1',
    box_className: 'card-box card-1',
  },
  {
    title: 'ScisGo™ Kits',
    description:
      'Phased 3-field HLA typing in a single assay using proven Scisco Genetics technology.',
    link: '/kits',
    main_image: card_2,
    mini_image: card_2_mini,
    className: 'card-2',
    box_className: 'card-box card-2',
  },
  {
    title: 'ScisCloud Software™',
    description:
      'Software products for laboratory workflow, typing analysis and data management.',
    link: '/technology',
    main_image: card_3,
    mini_image: card_3_mini,
    className: 'card-3',
    box_className: 'card-box card-3',
  },
]