import './navbar.css';
import logo from '../../images/logo.png';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import { Button, Menu, ThemeProvider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { orange } from '@mui/material/colors';

const Navbar = ({ backgroundColor, theme }) => {
  const [openHamMenu, setOpenHamMenu] = useState(null);
  const open = Boolean(openHamMenu);
  const handleClick = (event) => {
    setOpenHamMenu(event.currentTarget);
  };
  const handleClose = () => {
    scrollToTop();
    setOpenHamMenu(null);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  let activeStyle = {
    color: orange[600],
    fontWeight: '600',
  };

  return (
    <nav className='navbar-container' style={{ backgroundColor }}>
      <Link className='home-link' to='/' onClick={scrollToTop}>
        <img className='logo' src={logo} alt='scisco logo' />
      </Link>
      <div className='links'>
        <NavLink
          to='/services'
          className='link'
          onClick={scrollToTop}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Services
        </NavLink>
        <NavLink
          to='/kits'
          className='link'
          onClick={scrollToTop}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Kits
        </NavLink>
        <NavLink
          to='/technology'
          className='link'
          onClick={scrollToTop}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Technology
        </NavLink>
        <NavLink
          to='/resources_&_publications'
          className='link'
          onClick={scrollToTop}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Resources & Publications
        </NavLink>
        {/* <NavLink
          to='/about'
          className='link'
          onClick={scrollToTop}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          About
        </NavLink> */}
        <NavLink
          to='/contact'
          className='link'
          onClick={scrollToTop}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Contact
        </NavLink>
      </div>
      <ThemeProvider theme={theme}>
        <Button
          href='/contact/#order'
          color='secondary'
          variant='contained'
          className='order-button'
          sx={{
            borderRadius: '30px',
            borderColor: '#FF7D03 !important',
            fontWeight: 700,
            border: 2,
          }}
        >
          {' '}
          Order
        </Button>
      </ThemeProvider>

      <div className='ham-menu'>
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {open ? (
            <CloseIcon sx={{ color: '#FF7D03', fontSize: '42px' }} />
          ) : (
            <MenuIcon sx={{ color: '#FF7D03', fontSize: '42px' }} />
          )}
        </Button>
        <Menu
          id='basic-menu'
          anchorEl={openHamMenu}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <NavLink
            to='/services'
            className='link'
            onClick={handleClose}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Services
          </NavLink>
          <NavLink
            to='/kits'
            className='link'
            onClick={handleClose}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Kits
          </NavLink>
          <NavLink
            to='/technology'
            className='link'
            onClick={handleClose}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Technology
          </NavLink>
          <NavLink
            to='resources_&_publications'
            className='link'
            onClick={handleClose}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Resources & Publications
          </NavLink>
          {/* <NavLink
            to='/about'
            className='link'
            onClick={scrollToTop}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            About
          </NavLink> */}
          <NavLink
            to='/contact'
            className='link'
            onClick={handleClose}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Contact
          </NavLink>
          <NavLink
            to='/contact/#order'
            className='link'
            onClick={handleClose}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            Order
          </NavLink>
        </Menu>
      </div>
    </nav>
  );
};

export default Navbar;
