import './ResourcesPage.css';
import { Helmet } from 'react-helmet';
import ResourcesCard from '../../components/ResourcesCard/ResourcesCard';
import PublicationsCard from '../../components/PublicationsCard/PublicationsCard';
import Header from '../../components/header/Header';
import orange_line from '../../images/orange-line.png';


const ResourcesPage = ({ resources, publications, headers, theme }) => {
  return (
    <div>
      <Helmet>
        <title>Scisco Genetics Resources & Publications</title>
        <meta
          name='description'
          content='Scisco Genetics Resources & Publications'
        />
      </Helmet>
      <Header header={headers.filter((header) => header.key === 'resources')} />
      <div className='resources-main'>
        <section className='resources-container'>
          <h5 className='section-title'>
            Resources{' '}
            <img
              src={orange_line}
              className='orange-line'
              alt='decorative line'
            />{' '}
          </h5>
          <h2>
            Latest information about the science and clinical aspects of
            histocompatibility and immunogenetics
          </h2>
          <ResourcesCard resources={resources} theme={theme} />
        </section>
        <div className='pubs-outer-container'>
          <section className='pubs-inner-container' id='publications'>
            <div>
              <h5 className='section-title'>
                Publications{' '}
                <img
                  src={orange_line}
                  className='orange-line'
                  alt='decorative line'
                />{' '}
              </h5>
              <h2 className='pubs-desc'>
                Latest articles about histocompatibility and immunogenetics
              </h2>
            </div>

            <PublicationsCard publications={publications} />
          </section>
        </div>
      </div>
    </div>
  );
};

export default ResourcesPage;
