import './ServicesPageNavbar.css';
import { NavLink } from 'react-router-dom';

const ServicesPageNavbar = () => {
  return (
    <>
      <nav className='services-navbar'>
        <NavLink to='/services/#hla' className='services-link'>
          HLA Class I and II
        </NavLink>
        <NavLink to='/services/#kir' className='services-link'>
          KIR Haplotype
        </NavLink>
        <NavLink to='/services/#micab' className='services-link'>
          MIC AB
        </NavLink>
        <NavLink to='/services/#fcr' className='services-link'>
          FcR and IGHG
        </NavLink>
        <NavLink to='/services/#chimerism' className='services-link'>
          Chimerism
        </NavLink>
        <NavLink to='/services/#targeted-variation' className='services-link'>
          Targeted Variation
        </NavLink>
      </nav>
    </>
  );
};

export default ServicesPageNavbar;
