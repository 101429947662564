import './FcrTable.css';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { orange } from '@mui/material/colors';

const FcrTable = () => {
  function createData(
    gene,
    rs,
    region,
    position,
    dna,
    dnaRefseq,
    pos,
    aminoAcid,
    aaRefseq,
    effect
  ) {
    return {
      gene,
      rs,
      region,
      position,
      dna,
      dnaRefseq,
      pos,
      aminoAcid,
      aaRefseq,
      effect,
    };
  }

  const rows = [
    createData(
      'FCGR2A',
      'rs1801274',
      'Exon',
      500,
      'A>G',
      'NM_001136219.1',
      167,
      'His>Arg',
      'NP_001129691.1',
      'His(H)167: high affinity for IgG2'
    ),
    createData(
      'FCGR2B',
      'rs1050501',
      'Exon',
      695,
      'T>C',
      'NM_001002274.2',
      232,
      'Ile>Thr',
      'NP_001002274.1',
      'Thr(T)232: decreased inhibitory activity'
    ),
    createData(
      'FCGR2C',
      'rs759550223',
      'Exon',
      169,
      'C>T',
      'NM_201563.5',
      57,
      'Gln>Ter',
      'NP_963857.3',
      'Truncated non-functional protein'
    ),
    createData(
      'FCGR2B/C',
      'rs3219018',
      'Promoter',
      -386,
      'G>C',
      'NM_001002273.2',
      '',
      '',
      '',
      '-386C: higher promoter activity'
    ),
    createData(
      '',
      'rs780467580',
      'Promoter',
      -120,
      'T>A',
      'NM_001002273.2',
      '',
      '',
      '',
      '-120A: higher promoter activity'
    ),
    createData(
      'FCGR3A',
      'rs396991',
      'Exon',
      838,
      'T>G',
      'NM_001127592.2',
      280,
      'Phe>Val',
      'NP_001121064.2 ',
      'Val(V)280: higher affinity for IgG1 and 3, binds IgG4'
    ),
    createData(
      '',
      'rs200688856',
      'Exon',
      108,
      'C>G',
      'NM_000570.4',
      36,
      'Ser>Arg',
      'NP_000561.3',
      ''
    ),
    createData(
      '',
      'rs527909462',
      'Exon',
      114,
      'T>C',
      'NM_000570.4',
      38,
      'Leu>Leu',
      'NP_000561.3',
      ''
    ),
    createData(
      '',
      'rs448740',
      'Exon',
      194,
      'A>G',
      'NM_000570.4',
      65,
      'Asn>Ser',
      'NP_000561.3',
      ''
    ),
    createData(
      '',
      'rs5030738',
      'Exon',
      233,
      'C>A',
      'NM_000570.4',
      78,
      'Ala>Asp',
      'NP_000561.3',
      ''
    ),
    createData(
      '',
      'rs147574249',
      'Exon',
      244,
      'A>G',
      'NM_000570.4',
      82,
      'Asn>Asp',
      'NP_000561.3',
      ''
    ),
    createData(
      'FCGR3B',
      'rs2290834',
      'Exon',
      2316,
      'A>G',
      'NM_000570.4',
      106,
      'Ile>Val',
      'NP_000561.3',
      'FCGR3B*01 (NA1): higher affinity for IgG1 and 3'
    ),
    createData('', 'Haplotype', 108, 114, 194, 233, 244, 316, '', ''),
    createData('', 'FCGR3B*01 (NA1)', 'G', 'C', 'A', 'C', 'G', 'G', '', ''),
    createData('', 'FCGR3B*02 (NA2)', 'C', 'T', 'G', 'C', 'A', 'A', '', ''),
    createData('', 'FCGR3B*03 (SH)', 'C', 'T', 'C', 'A', 'A', 'A', '', ''),
    createData('', '', '', '', 'VNTR (37bp)*', '', '', '', '', ''),
    createData('', '', '', '', 'VNTR1 (1 repeat)', '', '', '', '', ''),
    createData('', '', '', '', 'VNTR2 (2 repeats)', '', '', '', '', ''),
    createData(
      'FCGRT (FcRn)',
      '',
      'Intron',
      'Intron1',
      'VNTR3 (3 repeats)',
      '',
      '',
      '',
      '',
      'VNTR3; High expression'
    ),
    createData('', '', '', '', 'VNTR4 (4 repeats)', '', '', '', '', ''),
    createData('', '', '', '', 'VNTR5 (5 repeats)', '', '', '', '', ''),
    createData(
      'FCER1A',
      'rs2251746',
      'Promoter',
      -36,
      'T>C',
      'NM_002001.3',
      '',
      '',
      '',
      '-36T: higher promoter activity – additional GATA-1 binding site'
    ),
    createData(
      '',
      'rs2427827 ',
      'Promoter',
      -285,
      'T>C',
      'NM_002001.3',
      '',
      '',
      '',
      '-285T: increased transcriptional activity – Sp1 site'
    ),
    createData(
      'MS4A2 (FCER1B)',
      'rs1441586',
      'Promoter',
      -211,
      'T>C',
      'NM_000139.4',
      '',
      '',
      '',
      '-211T: unknown/higher receptor expression'
    ),
    createData(
      '',
      'rs569108',
      'Exon',
      710,
      'A>G ',
      'NM_000139.4',
      237,
      'Glu>Gly',
      'NP_000130.1',
      'Gly(G)237: associated with higher expression'
    ),
    createData(
      'FCER2',
      'rs2228137',
      'Exon',
      181,
      'C>T',
      'NM_001207019.2',
      61,
      'Arg>Trp',
      'NP_001193948.2',
      'Trp(W)62: resistant to proteolytic cleavage'
    ),
    createData(
      'PIGR',
      'rs291102',
      'Exon',
      1739,
      'C>T',
      'NM_002644.3',
      580,
      'Ala>Val',
      'NP_002635.2',
      'Val(V)580: near endoproteolytic cleavage site/reduced efficiency of IgA release?'
    ),
    createData(
      '',
      'rs16986050',
      'Exon',
      739,
      'A>G',
      'NM_133269.3 ',
      247,
      'Ser>Gly',
      'NP_579803.1',
      'Gly(G)248: enhanced IgA-mediated responses; increased cytokine release'
    ),
    createData(
      'FCAR',
      'rs3816051 ',
      'Promoter',
      'T>C',
      -142,
      'NM_133269.3',
      '',
      '',
      '',
      '-142 C; High expression'
    ),
    createData(
      '',
      'rs12462181',
      'Promoter',
      -311,
      'T>C',
      'NM_133269.3',
      '',
      '',
      '',
      '-311 C; High expression'
    ),
  ];

  return (
    <>
      <p className='table-info'>
        Table 1. FcR polymorphisms detected with ScisGo-FcR-IGHG-v2
      </p>
      <TableContainer component={Paper} className='fcr-table'>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow
              sx={{ th: { backgroundColor: orange[600], fontWeight: 700 } }}
            >
              <TableCell align='center'>Gene</TableCell>
              <TableCell align='center'>RS</TableCell>
              <TableCell align='center'>Region</TableCell>
              <TableCell align='center'>Position</TableCell>
              <TableCell align='center'>DNA</TableCell>
              <TableCell align='center'>Ref Seq</TableCell>
              <TableCell align='center'>Position</TableCell>
              <TableCell align='center'>Amino Acid</TableCell>
              <TableCell align='center'>Ref Seq</TableCell>
              <TableCell align='center'>Effect</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  'td:first-of-type': { fontWeight: 600 },
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:nth-of-type(4) td': { border: 0 },
                  '&:nth-of-type(7) td, &:nth-of-type(8) td, &:nth-of-type(9) td, &:nth-of-type(10) td, &:nth-of-type(11) td, &:nth-of-type(12) td, &:nth-of-type(13) td, &:nth-of-type(14) td, &:nth-of-type(15) td':
                    { border: 0 },
                  '&:nth-of-type(17) td, &:nth-of-type(18) td, &:nth-of-type(19) td, &:nth-of-type(20) td, &:nth-of-type(21) td':
                    { border: 0 },
                  '&:nth-of-type(23) td': { border: 0 },
                  '&:nth-of-type(25) td': { border: 0 },
                  '&:nth-of-type(29) td, &:nth-of-type(30) td': { border: 0 },
                }}
              >
                <TableCell align='center'>{row.gene}</TableCell>
                <TableCell align='center'>{row.rs}</TableCell>
                <TableCell align='center'>{row.region}</TableCell>
                <TableCell align='center'>{row.position}</TableCell>
                <TableCell align='center'>{row.dna}</TableCell>
                <TableCell align='center'>{row.dnaRefseq}</TableCell>
                <TableCell align='center'>{row.pos}</TableCell>
                <TableCell align='center'>{row.aminoAcid}</TableCell>
                <TableCell align='center'>{row.aaRefseq}</TableCell>
                <TableCell align='center'>{row.effect}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p className='table-details'>
        Bournazos et al. Clinical and Experimental Immunology. 2009. 157:
        244–254, PMID: 19604264
      </p>
      <p className='table-details'>
        Mkaddem et al. Front Immunol. April 2019. 10:811, PMID: 31057544
      </p>
    </>
  );
};

export default FcrTable;
