import React from 'react';
import Header from '../../components/header/Header';
import orange_line from '../../images/orange-line.png';
import { Helmet } from 'react-helmet';
import terms_of_use from '../../database/terms-of-use.json';
import './TermsOfUse.css';

const TermsOfUsePage = ({ headers }) => {
  const terms = JSON.parse(JSON.stringify(terms_of_use));

  // Recursive rendering function
  const renderContent = (contentArray) => {
    return contentArray.map((item, index) => {
      const { tag, content } = item;
      // Render content based on the tag
      switch (tag) {
        case 'h5':
          return <h5 key={index}>{content.join(' ')}</h5>;
        case 'p':
          return content.map((paragraph, i) => <p key={`${index}-${i}`}>{paragraph}</p>);
        case 'strong':
          return content.map((paragraph, i) => <strong key={`${index}-${i}`}>{paragraph}</strong>);
        case 'ul': {
          // Render a list with nested list items
          const listItems = item.content.map((listItem, i) => {
            const { tag: listItemTag, content2 } = listItem;
            if (listItemTag === 'li') {
              return content2.map((liContent, j) => <li key={`${i}-${j}`}>{liContent}</li>);
            }
            return null; // Ignore unsupported tags in lists
          });
          return <ul key={index}>{listItems}</ul>;
        }
        default:
          return null; // Ignore unsupported tags
      }
    });
  };

  const sections = terms.sections.map((section, index) => (
    <div key={index} className='section'>
      <h5 className='section-title'>
        {section.title}
        <img
          src={orange_line}
          className='orange-line'
          alt='decorative line'
        />
      </h5>
      <div className='section-content'>{renderContent(section.description)}</div>
    </div>
  ));

  return (
    <>
      <Helmet>
        <title>{terms.main_title}</title>
        <meta name='description' content='Learn more about Scisco Genetics' />
      </Helmet>
      <Header header={headers.filter((header) => header.key === 'terms')} />
      <div className='terms-of-use-container'>
        <div>{sections}</div>
      </div>
    </>
  );
};

export default TermsOfUsePage;
