import './ResourcesCard.css';
import line from '../../images/line.png';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ThemeProvider } from '@mui/material/styles';
import { Button } from '@mui/material';

const ResourcesCard = ({ resources, theme }) => {
  const resource = resources.map((r, index) => {
    return (
      <div key={index} className='resource-card'>
        <img className='resource-logo' src={r.logo} alt='resource logo' />
        <img className='resource-line' src={line} alt='decorative line' />
        <div className='resource-info'>
          <h4 className='resource-name'>{r.name}</h4>
          <p className='resource-desc'>{r.description}</p>
        </div>
        <ThemeProvider theme={theme}>
          <Button
            href={r.link}
            target='_blank'
            className='resource-link'
            color='primary'
            variant='contained'
            endIcon={<OpenInNewIcon />}
            sx={{ borderRadius: '30px', fontWeight: 700 }}
          >
            {' '}
            Visit site
          </Button>
        </ThemeProvider>
      </div>
    );
  });

  return <div className='resource-cards'>{resource}</div>;
};

export default ResourcesCard;
