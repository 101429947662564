import './OrderCard.css';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import lab_test_order_form from '../../files/SGI-Service-Manifest_v1f.xlsx';
import kit_order_form from '../../files/SGI-Kit-Manifest.xlsx';
import sample_hla_report from '../../files/sample-reports/sample-hla-report.pdf';
import sample_handling_sheet from '../../files/Sample_Handling_Sheet_V6d.pdf';

const OrderCard = () => {
  return (
    <div className='order-cards'>
      <div className='order-card'>
        <section className='order-info'>
          <h5 className='order-stage'>Stage 1</h5>
          <h4 className='order-title'>Download manifest</h4>
          <p className='order-desc'>
            Download the Lab or Kit ordering manifest.
          </p>
        </section>

        <section className='order-links'>
          <a className='order-link' href={lab_test_order_form}>
            Download manifest for Lab Tests <FileDownloadOutlinedIcon />
          </a>
          <a className='order-link' href={kit_order_form}>
            Download manifest for Kits <FileDownloadOutlinedIcon />
          </a>
        </section>
      </div>

      <div className='order-card'>
        <section className='order-info'>
          <h5 className='order-stage'>Stage 2</h5>
          <h4 className='order-title'>Send manifest</h4>
          <p className='order-desc'>
            Send the completed manifest as an attachment.
          </p>
        </section>
        <section className='order-links'>
          <p className='send-to-email'>Send to email:</p>
          <a className='email' href='mailto: orders@sciscogenetics.com'>
            orders@sciscogenetics.com
          </a>
        </section>
      </div>

      <div className='order-card'>
        <section className='order-info'>
          <h5 className='order-stage'>Stage 3</h5>
          <h4 className='order-title'>Get a quote</h4>
          <p className='order-desc'>
            We will respond back with a quote within 24 hours.
          </p>
        </section>
        <section className='order-links'>
          <a
            className='order-link'
            href={sample_hla_report}
            target='_blank'
            rel='noreferrer'
          >
            View an example of the report <FileDownloadOutlinedIcon />
          </a>
        </section>
      </div>

      <div className='order-card'>
        <section className='order-info'>
          <h5 className='order-stage'>Stage 4</h5>
          <h4 className='order-title'>Send samples</h4>
          <p className='order-desc'>
            View our handling sheet on how to send different sample types.
          </p>
        </section>
        <section className='order-links'>
          <a
            className='order-link'
            href={sample_handling_sheet}
            target='_blank'
            rel='noreferrer'
          >
            View our Sample Handling Sheet <FileDownloadOutlinedIcon />
          </a>
        </section>
      </div>
    </div>
  );
};

export default OrderCard;
